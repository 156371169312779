// Use dynamic imports for web components

import './global';
import './buildProducts';
import './card-product';
import './cart';
import './collection-swatch-select';
import './color-heading';
import './color-select';
import './drop-down';
import './filter-banner-carousel';
import './hotspots';
import './images';
import './logo-slide';
import './mini-quiz';
import './mm';
import './modal';
import './product-carousel';
import './product-gallery';
import './product-form';
import './quickshop-variant-select';
import './rewards';
import './search';
import './shop-the-look';
import './size-chart-modal';
import './sticky-atc';
import './swatch-slider';
import './variant-details-select';
import './variant-select';
import './gift-guide-carousel';
import './anchor-links';
import './product-bundle';
import './promo-bar';
import './two-image-mobile-carousel';

if (document.querySelector('klaviyo-bis')) {
  import('./klaviyo-bis.js');
}

if (document.querySelector('quantity-select')) {
  import('./quantity-select.js');
}

if (document.querySelector('sidebar-drawer')) {
  import('./sidebar-drawer.js');
}

if (document.querySelector('fabric-slider')) {
  import('./fabric-slider.js');
}

if (document.querySelector('swatch-slider-lite')) {
  import('./swatch-slider-lite.js');
}
